import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"

import { TenantSignedInProvider } from "../hooks/useTenantSignedInContext"
import { TenantInfoProvider } from "../hooks/useTenantInfoContext"
import { SignUpProvider } from "../homepage/useSignUpContext"

import Faq from "../core/FAQs"
import GlobalStyles from "../homepage/GlobalStyles"
import "../homepage/layout.css"
import PageContact from "../homepage/PageContact"
import PageModalPlan from "../homepage/PageModalPlan"
import PageNav from "../homepage/PageNav"
import Pricing from "../homepage/Pricing"
import SignUp from "../homepage/SignUp"
import useShowHideModalContext from "../homepage/useShowHideModalContext"
import { segmentAction } from "../hooks/useSegmentTrack"
import PricingTable from "./PricingTable"

const Section = styled.section`
  :before,
  :after {
    content: "";
    clear: both;
    display: table;
  }
  line-height: 1.3;
  font-size: 1.25rem;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
  h1 {
    font-size: 4.5rem;
    line-height: 0.95;
    margin-bottom: 0.5em;
  }
  @media screen and (max-width: 900px) {
    font-size: 1rem;
    line-height: 1.4;
    h1 {
      font-size: 2.25rem;
      line-height: 0.95;
      margin-bottom: 0.5em;
    }
  }
  ${({ dark }) => {
    if (dark)
      return `
        background: var(--sparkle-drkblue);
        color: #fff;
    `
  }}
`
const Content = styled.div`
  padding: 8rem var(--content-pad);
  max-width: 900px;
  margin: auto;
  @media screen and (max-width: 900px) {
    padding: 3rem var(--content-pad);
  }
`
const BackBtn = styled.button`
  font-size: 0rem;
  font-weight: bold;
  position: absolute;
  width: 45px;
  height: 45px;
  background: #fff;
  opacity: 0.75;
  border-radius: 100%;
  left: var(--content-pad);
  top: var(--content-pad);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`
const plans = [
  {
    name: "Site",
  },
  {
    name: "Scheduler",
  },
  {
    name: "Assistant",
  },
]
const faqs = [
  {
    question:
      "What's the difference between the Scheduler and Assistant Plans?",
    answer: [
      "The ",
      <strong>Scheduler Plan</strong>,
      " makes it easy for your clients to book and pay for one-on-one appointments, group sessions, classes, or events with you via your Sparkle Site or by texting your Sparkle phone number. When the client texts your Sparkle phone number, your Sparkle Assistant handles the scheduling. You can also mass text your contacts from the platform.",
      <br />,
      <br />,
      "With the ",
      <strong>Assistant Plan</strong>,
      ", you can do all of the above, but you can also customize your Sparkle Assistant to answer FAQs on your behalf when people text your Sparkle phone number, message you on your Facebook business page, or via the chat on your website. The Assistant can also automatically upsell your products or services during the booking process.",
    ],
  },
  {
    question: "What happens if the Assistant can't answer a question?",
    answer: [
      "The Assistant asks customers their email at the beginning of the conversation. If someone asks a question the Assistant cannot answer, the customer will receive a message that says 'I'm sorry I am not able to fnd an answer.'",
      <br />,
      <br />,
      "You can choose to receive immediate email notifications if this occurs, and respond accordingly via email or SMS. Or, you can review the conversations that your Assistant had once a day if you sign up for the Daily Summary emails sent out each day. If you notice that a question has not been answered by the Assistant, you can follow up with that contact via email or SMS.",
    ],
  },
  {
    question: "What payment platforms do you integrate with?",
    answer: [
      "At this time we integrate with Stripe. We may offer addional platforms in the future. Please email ",
      <a href="mailto:community@wesparkle.co">community@wesparkle.co</a>,
      " if you would like to nominate a payment platform for us to add!",
    ],
  },
  {
    question: "Do you offer product fulfillment?",
    answer:
      "We do not offer fulfillment at this time. If a customer purchases an item from your shop, you will receive an email with the order information and can send the purchased item.",
  },
  {
    question: "Can I sync my Sparkle Scheduler with my existing calendar?",
    answer:
      "You can sync your Sparkle calendar with your Google or Outlook calendars. You can also integrate your Zoom account so that meeting links are automatically generated and included in the booking confirmations and reminders.",
  },
]

const Page = () => {
  const [modal, setModal] = useShowHideModalContext()
  const [plan, setPlan] = useState(0)
  const [showPlanOpts, setShowPlanOpts] = useState(true)
  const [pricingModel, setPricingModel] = useState(true)
  const [monthly, setMonthly] = useState(false)

  const showFeatureCallout = false
  return (
    <ThemeProvider theme={{ color: { accent: "#3790E2" } }}>
      <TenantInfoProvider>
        <TenantSignedInProvider>
          <SignUpProvider>
            <GlobalStyles />
            <PageNav
              links={[
                { name: "Features", href: "/pricing#features" },
                { name: "Pricing", href: "/pricing#top" },
                { name: "Our Story", href: "/about" },
                { name: "Contact", href: "/contact" },
              ]}
            />
            <Section dark id={"top"}>
              <Content>
                <Pricing
                  showFeatureCallout={showFeatureCallout}
                  setPricingModel={setPricingModel}
                  pricingModel={pricingModel}
                  monthly={monthly}
                  setMonthly={setMonthly}
                  onChange={(i) => {
                    setPlan(i)
                    setModal(true)
                  }}
                />
                <PricingTable />
                <Faq faqs={faqs} />
              </Content>
            </Section>
            {/* Pricing + Url modal */}
            <PageModalPlan show={modal} onClose={() => setModal(false)}>
              <Section dark style={{ width: "100%" }}>
                <Content style={{ position: "relative" }}>
                  <BackBtn
                    onClick={() => {
                      segmentAction.track("Homepage Url Modal Close")
                      setModal(false)
                    }}
                  >
                    {/* Backtick arrow */}
                    <svg
                      viewBox="0 0 100 100"
                      style={{
                        fill: "var(--sparkle-drkblue)",
                        width: 36,
                        transform: "translateX(-7.5%)",
                        height: "auto",
                      }}
                    >
                      <path d="M66.43,20a4,4,0,0,0-5.66,0L33.58,47.17a4,4,0,0,0,0,5.66L60.77,80a4,4,0,1,0,5.65-5.66L42.06,50,66.42,25.66A4,4,0,0,0,66.43,20Z"></path>
                    </svg>
                  </BackBtn>
                  <h2 style={{ marginTop: "2rem", marginBottom: ".5rem" }}>
                    Your site
                  </h2>
                  <SignUp
                    btnText={"Continue"}
                    plan={plan}
                    pricingModel={pricingModel}
                    monthly={monthly}
                    // WHERE WE GO TO PRICING OR MAKING THE SITE
                  />
                  {showPlanOpts && (
                    <Pricing
                      setPricingModel={setPricingModel}
                      pricingModel={pricingModel}
                      monthly={monthly}
                      setMonthly={setMonthly}
                      selected={plan}
                      noBtns // cleaner less actions
                      onChange={(i) => setPlan(i)}
                      noHeading
                      planName={plans[plan].name}
                    />
                  )}
                </Content>
              </Section>
            </PageModalPlan>
            <Section dark id={"follow-for-more"} as={"footer"}>
              <Content style={{ padding: "var(--content-pad)" }}>
                <PageContact />
              </Content>
            </Section>
          </SignUpProvider>
        </TenantSignedInProvider>
      </TenantInfoProvider>
    </ThemeProvider>
  )
}

export default Page
