import React from "react"
import Seo from "../core/Seo"
import PricingLayout from "../pricing/Page"
import { ThemeProvider } from "styled-components"
import { ShowHideModalProvider } from "../homepage/useShowHideModalContext"

const PricingPage = () => {
  return (
    <ThemeProvider theme={{ colors: { accent: "#3790E2" } }}>
        <Seo title="We Sparkle • Simple Tools for Solopreneurs" includeZohoTracking />
        <ShowHideModalProvider>
          <PricingLayout />
        </ShowHideModalProvider>
    </ThemeProvider>
  )
}

export default PricingPage