import React from "react"
import styled from "styled-components"
import { ButtonBase } from "../homepage/Buttons"

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  background: var(--sparkle-drkblue);
  position: sticky;
  /* Should match height of sticky nav */
  top: 68px;
  border-bottom: 1px solid #3a506f;
  gap: 4rem;
  padding: 1.5rem 0;
  z-index: 20;

  h2 {
    font-size: 24px;
    margin: 0 0 0.5rem;
  }
  p {
    font-size: 1rem;
    margin: 0;
  }
  @media screen and (max-width: 900px) {
    top: 0;
    grid-template-columns: 1fr;
    gap: 1.75rem;

    h2 {
      font-size: 1.25rem;
    }
  }
`
const TablePlans = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
`
const TablePlan = styled.div`
  text-align: center;

  h4 {
    margin: 0.5rem 0;
  }
  p {
    font-size: 12px;
    color: #a7b0be;
  }
  @media screen and (max-width: 900px) {
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 1rem;
    }
    p {
      font-size: 0.7rem;
    }
  }
`
const TablePlanName = styled.h3`
  color: ${({ color }) => color};
`
const TableFeatures = styled.div`
  margin-bottom: 6rem;
  @media screen and (max-width: 900px) {
    margin-bottom: 3rem;
  }
`
const FeatureSection = styled.div`
  border-bottom: 1px solid #3a506f;
  padding: 2rem 0;
  gap: 1rem;

  &:last-of-type {
    border-bottom: none;
  }
  .feature-heading {
    margin-bottom: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
`
const FeatureItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 4rem;
  margin-bottom: 1.75rem;

  p {
    margin: 0;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    gap: 0;
    border: 1px solid #3a506f;
    border-radius: 10px;

    p {
      padding: 1rem;
      border-bottom: 1px solid #3a506f;
    }
  }
`
const FeaturePlans = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 2rem;

  @media screen and (max-width: 900px) {
    padding: 1.5rem 1rem;
  }
`
const FeaturePlan = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--sparkle-green);

  &:first-child {
    background-color: var(--sparkle-yellow);
  }
  &:last-child {
    background-color: var(--sparkle-brightblue);
  }
  &.enabled {
    transform: rotate(45deg);

    &::after {
      position: absolute;
      content: "";
      display: block;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      width: 6px;
      height: 13px;
      border: solid var(--sparkle-drkblue);
      border-width: 0 2px 2px 0;
    }
  }
  &.disabled {
    background-color: #465b78;
    transform: rotate(-45deg);

    &::before,
    &::after {
      position: absolute;
      content: "";
      display: block;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      background-color: var(--sparkle-drkblue);
    }
    &::before {
      height: 12px;
      width: 1.5px;
    }
    &::after {
      height: 1.5px;
      width: 12px;
    }
  }
`
const Link = styled(ButtonBase)`
  position: relative;
  transform: translateZ(0);
  width: 100%;
  opacity: 1;
  color: var(--sparkle-drkblue) !important;
  padding: 0.7em 0.5em;
  font-weight: bold;
  font-size: 1rem;
  background: ${({ bg }) => bg};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border-radius: 100px;
  margin: 1.5rem 0rem 0rem;
  transition: 0.15s;
`
const plans = [
  {
    name: "Site",
    accentColor: "var(--sparkle-yellow)",
    monthPrice: "0",
  },
  {
    name: "Scheduler",
    accentColor: "var(--sparkle-green)",
    monthPrice: "29",
    yearPrice: "300",
  },
  {
    name: "Assistant",
    accentColor: "var(--sparkle-brightblue)",
    monthPrice: "49",
    yearPrice: "540",
  },
]
const features = [
  {
    featureHeading: "Personal Website",
    featureList: [
      {
        featureName: "Share links",
        featurePlans: [true, true, true],
      },
      {
        featureName: "Collect leads",
        featurePlans: [true, true, true],
      },
      {
        featureName: "Get paid for products & services",
        featurePlans: [true, true, true],
      },
      {
        featureName: "Collect tips",
        featurePlans: [true, true, true],
      },
      {
        featureName: "Encourage reviews",
        featurePlans: [false, true, true],
      },
      {
        featureName: "Automatically answer FAQs with AI automation",
        featurePlans: [false, false, true],
      },
      {
        featureName: "Automated upsell",
        featurePlans: [false, false, true],
      },
    ],
  },
  {
    featureHeading: "Scheduling",
    featureList: [
      // {
      //   featureName:"Offer one appointment type",
      //   featurePlans:[true, true, true,]
      // },
      {
        featureName: "Offer multiple appointment types",
        featurePlans: [false, true, true],
      },
      {
        featureName: "Dedicated business phone number and voicemail",
        featurePlans: [false, true, true],
      },
      {
        featureName: "Create group events",
        featurePlans: [false, true, true],
      },
      {
        featureName: "Automated reminders",
        featurePlans: [false, true, true],
      },
      {
        featureName: "Mass texting your contacts",
        featurePlans: [false, false, true],
      },
    ],
  },
  {
    featureHeading: "Integrations",
    featureList: [
      {
        featureName: "Outlook or Google Calendar Integration",
        featurePlans: [false, true, true],
      },
      {
        featureName: "Zoom Integration",
        featurePlans: [false, true, true],
      },
      {
        featureName: "Facebook Integration",
        featurePlans: [false, false, true],
      },
    ],
  },
  {
    featureHeading: "Self & Community Care",
    featureList: [
      {
        featureName: "Wellness Tools",
        featurePlans: [false, true, true],
      },
      {
        featureName: "Personalized coaching",
        featurePlans: [false, false, true],
      },
    ],
  },
]

const PricingTable = () => {
  return (
    <div id="features">
      <TableHeader>
        <div>
          <h2>Features</h2>
          <p>Effortlessly create a site that grows with your business</p>
        </div>
        <TablePlans>
          {plans.map(
            ({ name, accentColor: color, monthPrice, yearPrice }, i) => {
              return (
                <TablePlan key={i}>
                  <TablePlanName color={color}>{name}</TablePlanName>
                  <h4>${monthPrice}/mo</h4>
                  {yearPrice && <p>${yearPrice}/yr (Save $48/yr)</p>}
                </TablePlan>
              )
            }
          )}
        </TablePlans>
      </TableHeader>
      <TableFeatures>
        {features.map(({ featureHeading, featureList }, j) => {
          return (
            <FeatureSection key={j}>
              <p className="feature-heading">
                <strong>{featureHeading}</strong>
              </p>
              {featureList.map(({ featureName, featurePlans }, k) => {
                return (
                  <FeatureItem key={k}>
                    <p>{featureName}</p>
                    <FeaturePlans>
                      {featurePlans.map((item, l) => {
                        return (
                          <FeaturePlan
                            key={l}
                            className={item ? "enabled" : "disabled"}
                          />
                        )
                      })}
                    </FeaturePlans>
                  </FeatureItem>
                )
              })}
            </FeatureSection>
          )
        })}
      </TableFeatures>
    </div>
  )
}

export default PricingTable
