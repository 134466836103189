import React from "react"
import styled from "styled-components"

const Container = styled.article`
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    h3 {
        font-size: 32px;
    }
    @media screen and (max-width: 900px) {
    }
`
const Option = styled.div`
    h4 {
        font-size: 18px;
        margin-bottom: 1rem;
    }
    p {
        margin: 0.5rem 0 2rem;
        font-size: 1rem;
    }
    a {
        color: white;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    details {
        display: block;
        border-bottom: 1px solid white;
        padding: 0rem;

        summary {
            display: grid;
            grid-template-columns: 1fr 22px;
            justify-content: space-between;
            position: relative;
            list-style: none;
            padding: 3.5rem 0 0;
            gap: 1rem;
            cursor: pointer;

            &::-webkit-details-marker {
                display: none;
            }
            svg {
                transition: 0.2s;
            }
        }
        div {
            max-width: 650px;
            line-height: 1.4;
        }
        &[open] {
            svg {
                transform: rotate(180deg);
            }
        }
        :after {
            content: none;
        }
    }
`

const Faq = ({faqs}) => {
    return (
        <Container>
            <h3>Frequently Asked Questions</h3>
            {faqs.map((item, i) => {
                return (
                    <Option
                    key={i}
                    >
                        <details>
                            <summary>
                                <h4>{item.question}</h4>
                                <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m6 9 5 5 5-5m5 2c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10Z" stroke="#fff"/></svg>
                            </summary>
                            <div>
                                <p>{item.answer}</p>
                            </div>
                        </details>
                    </Option>
                )
            })}
        </Container>
    )
}

export default Faq
